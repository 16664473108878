<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="订购流量池总包"
      @ok="handleSubmit"
    >
      <a-form
        class="iotplt-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="选择套餐"
        >
          <a-select
            @change="handleProductChange"
            show-search
            allow-clear
            :filter-option="filterOption"
            :loading="loadingProductionOptions"
            v-decorator="['product_price_history_id', {
              rules: [
                { required: true, message: '请选择套餐' }
              ]
            }]"
          >
            <a-select-option
              v-for="option in productionOptions"
              :key="option.product_price_history_id+'_'+option.cycles"
              :value="option.product_price_history_id+'_'+option.cycles"
              :cycle="option.cycles"
              :product_price_history_id="option.product_price_history_id"
              :product_type="option.product_type"
            >
              {{ option.product_name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="确认周期数量"
        >
          <a-input-number
            :step="1"
            style="width: 100%"
            @change="handleCyclesChange"
            v-decorator="['cycles', {
              rules: [
                {
                  required: true, message: '请确认周期数量',
                  validator: validateCycles
                }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="订购金额(元)">
          <a-spin :spinning="loadingTotalFee">
            <a-input
              disabled
              v-decorator="['total_fee', {
                rules: [
                  { validator: validateSubscribeTotalFee }
                ]
              }]"
            />
          </a-spin>
        </a-form-item>

        <a-form-item
          label="可用额度(元)"
          extra="可用额度=信用额度+账户余额-锁定金额"
        >
          <a-input
            disabled
            v-model="this.availableFund"
          />
        </a-form-item>

        <a-form-item :label="beginAtTitle">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledBeginAt"
            v-decorator="['begin_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="endAtTitle">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledEndAt"
            v-decorator="['end_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`备注`">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' }
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { subscribeTrafficPool, subscribeTrafficPoolProductsSearch, subscribeTrafficPoolCalculateFee } from '@/api/traffic_pool'
import { formatDate } from '@/utils/time'
import { findAgentFinanceOverview } from '@/api/agent'

export default {
  name: 'SubscribeAgentTrafficPool',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingProductionOptions: false,
      loadingTotalFee: false,
      productionOptions: [],
      selectedCycles: 0,
      selectedProductPriceHistoryId: 0,
      availableFund: 0, // 可用额度
      amountTitle: '扩容容量(GB)',
      beginAtTitle: '有效期开始日期',
      endAtTitle: '有效期结束日期',
      agent: {},
      isCanSubmit: false, // 是否可以提交订购
      submitting: false,
      form: this.$form.createForm(this, { name: 'agent_trafficpool_expandrecord' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    trafficPoolId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchsubscribeTrafficPoolsProductsSearch()
    this.fetchAvailableFund()
  },
  methods: {
    // 获取可用额度
    fetchAvailableFund() {
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.availableFund = res.data.available
        }
      })
    },

    fetchsubscribeTrafficPoolsProductsSearch() {
      subscribeTrafficPoolProductsSearch(this.trafficPoolId).then((res) => {
        if (res.code === 0) {
          this.productionOptions = res.data
        }

        this.loadingProductionOptions = false
      })
    },

    handleProductChange(value, e) {
      this.selectedCycles = e.data.attrs.cycle
      this.selectedProductPriceHistoryId = e.data.attrs.product_price_history_id
    },

    validateCycles(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入订购周期数量')
        return
      }

      if (!/^[1-9]\d*$/.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      value = parseInt(value)
      if (!value || value < 1 || value > 9999) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      if (value !== this.selectedCycles) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('与选择的套餐周期不匹配')
        return
      }

      callback()
    },

    validateSubscribeTotalFee(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请等待订购金额计算完成')
        return
      }

      if (value > this.availableFund) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('账户可用额度低于订购金额，请充值后再进行订购')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    // 清空订购金额
    clearSubscribeTotalFee() {
      this.form.setFieldsValue({
        total_fee: undefined
      })
    },

    // 处理订购周期数量变更
    handleCyclesChange() {
      this.isCanSubmit = false
      this.clearSubscribeTotalFee()
      this.calculateSubscribeTotalFee()
    },

    // 计算订购金额
    calculateSubscribeTotalFee() {
      this.form.validateFields(['cycles'], { force: true }, (err, values) => {
        if (!err) {
          this.loadingTotalFee = true
          subscribeTrafficPoolCalculateFee(this.trafficPoolId, { product_price_history_id: this.selectedProductPriceHistoryId, cycles: values.cycles }).then((res) => {
            if (res.code === 0 && res.data.cycles === values.cycles) {
              if (res.data.is_can_subscribe) {
                this.form.setFieldsValue({
                  total_fee: res.data.total_fee
                })
                this.isCanSubmit = true
              } else {
                this.$error({
                  title: '提示',
                  content: res.data.error
                })
              }
            }
            this.loadingTotalFee = false
          })
        }
      })
    },

    disabledBeginAt(current) {
      return current && current > this.$moment().endOf('day')
    },

    disabledEndAt(current) {
      return current && current < this.$moment().endOf('day')
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            traffic_pool_id: this.trafficPoolId,
            product_price_history_id: this.selectedProductPriceHistoryId,
            begin_at: formatDate(values.begin_at),
            end_at: formatDate(values.end_at)
          }
          subscribeTrafficPool(this.trafficPoolId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
