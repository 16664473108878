<template>
  <div>
    <search-traffic-pool-expand-records
      @submit="submitSearch"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">订购</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="iccid"
    />

    <!-- 记录扩容记录模态框 -->
    <New-expand-records
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :agent-id="agentId"
      @completed="fetchData"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import { findTrafficPoolExpandRecords } from '@/api/traffic_pool'
import Pagination from '@/components/Pagination'
import SearchTrafficPoolExpandRecords from '@/views/traffic_pools/expand_records/Search'
import NewExpandRecords from '@/views/traffic_pools/expand_records/New'

export default {
  name: 'TrafficPoolExpandRecordsList',
  components: {
    SearchTrafficPoolExpandRecords,
    NewExpandRecords,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '有效期开始时间',
          dataIndex: 'begin_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'begin_time' ? this.sort.sort_order : false
        },
        {
          title: '有效期结束时间',
          dataIndex: 'end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'end_time' ? this.sort.sort_order : false
        },
        {
          title: '扩容容量',
          dataIndex: 'capacity'
        },
        {
          title: '已使用容量',
          dataIndex: 'usage'
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at'
        }
      ]
    },

    trafficPoolId() {
      return parseInt(this.$route.params.id)
    },

    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTrafficPoolExpandRecords(this.trafficPoolId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    showNewModal() {
      this.isShowNewModal = true
    }
  }
}
</script>
